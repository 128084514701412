.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: "Inter", sans-serif;
}

.panelContainer {
  width: 20%;
  min-width: 348px;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.12) 4px 0px 24px;
  z-index: 1;
  visibility: hidden;
  overflow: scroll;
}

.panelContainer[style*="visibility: hidden"] {
  visibility: visible;
  width: 0;
  min-width: 0;
  padding: 0;
}

.canvasContainer {
  width: 100%;
  background-color: #fafafa;
  flex: 1;
}

.canvasContainer[style*="padding-left: 200px"] {
  padding-left: 0px;
}

.toggleButton {
  font-family: "Inter", sans-serif;
  position: absolute;
  margin-left: 10px;
  font-size: 1em;
  padding: 12px 20px;
  border: 0px;
  cursor: pointer;
  z-index: 2;
}

.saveButton {
  font-family: "Inter", sans-serif;
  margin-left: 10px;
  font-size: 1em;
  display: "flex";
  padding: 12px 20px;
  justify-content: "center";
  align-items: "center";
  border: 0px;
  cursor: pointer;
  z-index: 3;
  color: #525252;
  background-color: #eee;
}

.saveButton:hover {
  background-color: #e1e1e1;
}
