@font-face {
  font-family: "IBM Plex Sans";
  src: url("./fonts/IBMPlexSans-Medium.ttf") format("truetype");
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  margin: 0;
  line-height: 1.1;
}

.overlay h2 {
  color: #007aff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 0px;
}

.overlay p {
  color: #ccc;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.2;
  margin-bottom: 20px;
}

.box {
  width: 560px;
  height: 840px;
  display: flex;
  align-items: flex-start;
  padding: 8px 24px 32px 24px;
  gap: 24px;
  margin: auto;
  color: #007aff;
  background-color: #fff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 1.5em;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
}

.img {
  flex: 1 0 0;
  align-self: stretch;
}

.boxMobile {
  width: 90vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 12px 16px 12px;
  gap: 12px;
  margin: auto;
  color: #007aff;
  background-color: #fff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 1em;
}

.info {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.OnboardButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.8em;
}

.OnboardButton {
  padding: 10px 20px;
  margin: 5px;

  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.OnboardButton:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.OnboardButton:hover:not(:disabled) {
  background-color: #0056b3;
}

.OnboardButtonBack {
  padding: 10px 20px;
  margin: 5px;
  color: #bbb;
  background-color: #eee;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.OnboardButtonBack:disabledback {
  background-color: #ddd;
  color: #eee;
  cursor: not-allowed;
}

.OnboardButtonBack:hover:not(:disabled) {
  background-color: #e1e1e1;
}

.OnboardButton {
  font-size: 0.8em;
  padding: 12px 20px;
  display: "flex";
  justify-items: "center";
  align-items: "center";
  border: 0px;
  cursor: pointer;
  z-index: 3;
}

.OnboardButtonBack {
  font-size: 0.8em;
  padding: 12px 20px;
  display: "flex";
  justify-items: "center";
  align-items: "center";
  border: 0px;
  cursor: pointer;
  z-index: 3;
  color: #525252;
  background-color: #eee;
}
