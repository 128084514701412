.node {
  width: 200px;
  cursor: default;
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: medium;
}

.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.textarea {
  resize: none;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 2px 4px 12px;
  padding: 8px;
  height: 100%;
  min-height: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  border: none;
  order: 0;
  outline-offset: 0;
  outline-width: 2px;
  width: 184px;
  font-family: "Inter", sans-serif;
  z-index: 20;
}

.inputContainer {
  display: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.inputContainer .button {
  display: none;
}

.inputContainer:focus-within .button {
  display: inline-block;
}

.button {
  /* display: flex; */
  top: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007aff;
  color: #fff;
  border: 0px;
  cursor: pointer;
  order: 1;
  box-sizing: border-box;
  font-size: 0;
  padding: 4px;
  margin-top: 2px;
  outline: #fafafa;
  outline-style: solid;
  outline-width: 2px;
  z-index: 1;
}

.button:hover {
  background-color: #0067d6;
}

.plus {
  width: 11px;
  height: 11px;
  color: #007aff;
}
