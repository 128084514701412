@font-face {
  font-family: "IBM Plex Sans";
  src: url("./fonts/IBMPlexSans-Medium.ttf") format("truetype");
}

.panel {
  display: flex;
  min-width: 300px;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: auto;
  gap: 48px;
  padding: 0px 24px 0px 24px;
  font-size: medium;

  z-index: -1;
}

.contextGroup {
  padding: 24px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  flex: none;
  flex-grow: 1;
  gap: 12px;
}

.rest {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 48px;
  margin-top: auto;
  padding: 0px 0px 48px 0px;
}

.textarea {
  resize: none;
  min-height: 64px;
  flex: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border: none;
  background-color: #eeeeee;
  padding: 16px;

  font-size: medium;
}

.sliderGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.smallGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addAssumptionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: none;
  background-color: #eeeeee;
  cursor: pointer;
  width: 100%;
  font-size: medium;
}

.headline {
  margin: 0px;
  font-size: xx-large;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  color: #007aff;
}

.slider {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
}

.input {
  border: none;
  background-color: #eeeeee;
  padding: 16px;
  font-size: medium;
  appearance: textfield;
  text-align: center;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.checkboxGroup {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxGpt {
  position: relative;
  margin-right: -8px;
  -webkit-appearance: none;
}

.checkboxGpt::before {
  width: 20px;
  height: 20px;

  border: 4px solid #eeeeee;
  background-color: #eeeeee;
  display: block;
  content: "";
  float: left;
  position: relative;
}
.checkboxGpt:checked::before {
  background-color: #007aff;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.closeButton {
  display: flex;
  margin-left: 10px;
  height: 40px;
  width: 40px;
  border: 0px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: #525252;
  background-color: #eee;
}
